import { FC } from "react";
import {
  ArrowDown,
  ArrowDownUp,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpDown,
  Checked,
  ReconcileIcon,
  Tick,
  XIcon,
} from "../icons";
import { Card } from "../shared";
import { default as Mada } from "../icons/mada.png";
import { default as Visa } from "../icons/visa.png";
import { default as Mastercard } from "../icons/mastercard.png";
import { default as American_Express } from "../icons/American_Express.png";
import { default as GCCNET } from "../icons/GCCNET.png";
import { default as Discover } from "../icons/Discover.png";
import { default as UnionPay } from "../icons/UnionPay.png";
import { default as Maestro } from "../icons/Maestro.png";
import { default as JCB } from "../icons/JCB.png";
import { formatPrice } from "../utils";
import { TransactionListItemType } from "../types/transaction";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { isReadable } from "stream";

const PaymentSchemeIcons: { [key: string]: string } = {
  P1: Mada,
  VC: Visa,
  MC: Mastercard,
  DM: Maestro,
  AX: American_Express,
  JC: JCB,
  DC: Discover,
  UP: UnionPay,
  GN: GCCNET,
};

export const TransactionListItem: FC<TransactionListItemType> = ({
  role,
  title,
  onClick,
  item,
}) => {
  //default is xs - to sm -> we reach 600
  const { i18n, t } = useTranslation();
  return (
    <div className="mt-3 overflow-hidden">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card
        classNames="flex items-center justify-between  gap-5 lg:py-4 lg:px-4 sm:p-2 text-[.5rem] lg:text-xs 2xl:text-sm w-full "
        onClick={onClick}
      >
        <div className=" flex font-bold ">
          <TransactionType
            type={item?.transaction_type}
            is_reversed={item?.is_reversed}
          />
        </div>

        <div className=" md:hidden flex-shrink flex-grow"></div>

        {item?.is_reconcilied && (
          <div className="md:hidden">
            <ReconcileIcon color="text-current" containerClassNames="!p-0" />
          </div>
        )}
        <div className=" flex items-center gap-2">
          <span
            className={
              (item?.is_approved
                ? "bg-green-200 p-1 text-green-600"
                : "bg-red-200 text-red-600 px-1") +
              " flex items-center gap-1 sm:px-3 md:px-1 rounded-full "
            }
          >
            {item?.is_approved ? (
              <Checked size={18} color={"text-current"} />
            ) : (
              <XIcon
                size={18}
                color={"text-current"}
                containerClassNames={"py-1"}
              />
            )}
            <span className="hidden xs:block md:hidden sm:truncate px-1">
              {i18n.language == "ar"
                ? item.receipts?.[0]?.status_message?.arabic
                : item.receipts?.[0]?.status_message?.english}
            </span>
          </span>
          <FormatPrice item={item} />
        </div>
        {!item?.is_approved && (
          <div className="details-hide  hidden xl:block  rounded-full bg-red-100 2xl:mx-2  py-1 px-1 2xl:px-2 text-[.6rem] text-center font-medium text-red-800">
            {i18n.language == "ar"
              ? item.receipts?.[0]?.status_message?.arabic
              : item.receipts?.[0]?.status_message?.english}{" "}
          </div>
        )}

        <div className="hidden md:block flex-shrink flex-grow"></div>

        <div className="hidden md:flex items-center gap-2 ">
          <div>
            <img
              src={PaymentSchemeIcons[item?.card_scheme_id]}
              className="w-5 lg:w-7 "
            />
          </div>
          <div className="flex flex-col lg:leading-5">
            <span className="font-bold whitespace-nowrap">
              {item?.receipts?.[0].pan}
            </span>
            <span>{item?.receipts?.[0].start_time}</span>
          </div>
        </div>

        <div className="hidden md:flex flex-col">
          <span className="font-bold whitespace-nowrap">
            RRN: {item?.receipts?.[0].retrieval_reference_number}
          </span>
          <span>STAN: {item?.receipts?.[0].system_trace_audit_number}</span>
        </div>
        {item?.is_reconcilied && (
          <div className="hidden md:flex items-center gap-1 rounded-full bg-gray-900 py-1 lg:py-2 px-3 text-white">
            <span>
              <ReconcileIcon
                size={20}
                color="text-current"
                containerClassNames="!p-0"
              />
            </span>
            <span className="block">Reconciled</span>
          </div>
        )}

        <div className="">
          {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
        </div>
      </Card>
    </div>
  );
};

export const TransactionType = ({
  type,
  is_reversed,
}: {
  type: any;
  is_reversed: boolean;
}) => {
  const { i18n, t } = useTranslation();
  const name = i18n.language == "ar" ? type.name.arabic : type.name.english;
  const color =
    type.name.english == "PURCHASE" ? "text-indigo-600" : "text-orange-600";

  const handelType = () => {
    switch (type.name.english) {
      case "PURCHASE":
        return (
          <div className="flex items-center text-indigo-600">
            {is_reversed ? (
              <ArrowDownUp color="text-current" />
            ) : (
              <ArrowUp color="text-current" />
            )}
          </div>
        );
      case "REFUND":
        return (
          <div className="flex items-center text-orange-600">
            {is_reversed ? (
              <ArrowUpDown color="text-current" />
            ) : (
              <ArrowDown color="text-current" />
            )}
          </div>
        );
    }
  };
  return (
    <div className={"flex items-center " + color}>
      {handelType()}
      <span className="flex flex-col leading-3 ">
        {name}
        <span className="text-black">
          {is_reversed && (t("reversed") as string)}
        </span>
      </span>
    </div>
  );
};

export const FormatPrice = ({ item }: { item: any }) => {
  const { i18n, t } = useTranslation();

  return (
    <span className=" flex items-baseline gap-1 font-bold">
      {" "}
      <small className="lg:text-[.7rem] font-normal">
        {i18n.language == "ar"
          ? item.receipts?.[0]?.currency?.arabic
          : item.receipts?.[0]?.currency?.english}
      </small>{" "}
      {item.amount_authorized?.value}{" "}
    </span>
  );
};
