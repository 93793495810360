import Api, { List } from ".";

interface Terminals extends List {
  filter: { [key: string]: any };
  merchantId?: string;
}

interface AssignTerminal {
  id: string;
  mobile: string;
  sendSMS: boolean;
}
export enum UnAssignEnum {
  user = "user",
  device = "device",
}
interface UnassignTerminal {
  id: string;
  type: UnAssignEnum;
}

interface Terminal {
  id: string;
}

interface NewTerminal {
  merchant_id: string;
  terminal_ids: string[];
}

interface NewNexoTerminal {
  merchant_id: string;
  tms2Data: any;
}
interface TerminalResponse {
  terminal: {
    id: string;
    name?: string;
    tid?: string;
    user: {
      name: string;
    };
    client?: {
      name: string;
    };
    merchant: {
      name: string;
    };
    is_assigned_to_user: boolean;
    is_connected: boolean;
  };
  profile: any;
}

export const listTerminalsApi = async ({
  limit = 30,
  page = 1,
  filter,
  merchantId,
}: Terminals) => {
  try {
    const response = await Api({
      method: "get",
      url: "terminals",
      params: {
        limit,
        page,
        ...filter,
        merchant_id: merchantId,
      },
    });
    return response.data as { pages: any; terminals: TerminalResponse[] };
  } catch (e: any) {
    throw e;
  }
};

export const findTerminalApi = async ({ id }: Terminal) => {
  try {
    const response = await Api({
      method: "get",
      url: "terminals/" + id,
    });
    return response.data as TerminalResponse;
  } catch (e: any) {
    throw e;
  }
};

export const createTerminalApi = async ({
  merchant_id,
  terminal_ids,
}: NewTerminal) => {
  try {
    const response = await Api({
      method: "post",
      url: "terminals",
      data: {
        merchant_id,
        trsm_codes: terminal_ids,
      },
    });
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const createNexoTerminalApi = async ({
  merchant_id,
  tms2Data,
}: NewNexoTerminal) => {
  try {
    const response = await Api({
      method: "post",
      url: "terminals/nexo",
      data: {
        merchant_id,
        tms2_data: [
          {
            terminalId: tms2Data.tid,
            trsm: tms2Data.trsm,
            serialNumber: tms2Data.serialNo,
            trsmIdCounter: tms2Data.counter,
            model: tms2Data.model,
            make: tms2Data.make,
            appVersion: tms2Data.version,
            nsp: tms2Data.nsp,
          },
        ],
      },
    });
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};
export const assginTerminalApi = async ({
  id,
  mobile,
  sendSMS,
}: AssignTerminal) => {
  try {
    const response = await Api({
      method: "put",
      url: "terminals/connect",
      data: {
        id,
        mobile: "+966" + mobile,
        sendSMS,
      },
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const unAssginTerminalApi = async ({ id, type }: UnassignTerminal) => {
  try {
    const response = await Api({
      method: "put",
      url: "terminals/disconnect",
      data: {
        id,
        type,
      },
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const fileDownloadApi = async ({
  id,
  isFull,
}: {
  id: string;
  isFull: boolean;
}) => {
  try {
    const response = await Api({
      method: "patch",
      url: "terminal/configuration",
      data: {
        terminalId: id,
        isFull,
      },
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const nexoFileDownloadApi = async ({ id }: { id: string }) => {
  try {
    const response = await Api({
      method: "patch",
      url: "terminal/configuration/nexo",
      data: {
        terminalId: id,
      },
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const terminalSyncApi = async ({ id }: { id: string }) => {
  try {
    const response = await Api({
      method: "patch",
      url: "terminal/sync/nexo",
      data: {
        terminalId: id,
      },
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};
