import { FC, useState } from "react";
import { TerminalIcon, Button, Select, Input, Errors } from "../../lib";
import { Modal } from "../../lib/modal";
import { MerchantResponse } from "../../utils/api/merchants-apis";
import { createNexoTerminalApi } from "../../utils/api/terminals-apis";
import LoadingSpinner from "../../layout/loading-spinner";
import { ListTRSMResponseApi } from "../../utils/api/trsm-apis";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  merchants: MerchantResponse[];
  trsms: ListTRSMResponseApi;
  refreshList: () => void;
  fetchTRSMs: ({}: { page?: number; search?: string }) => void;
  setTrsms: React.Dispatch<React.SetStateAction<ListTRSMResponseApi>>;
};

const ManageNexoTerminals: FC<Props> = ({
  isOpen,
  toggleModal,
  merchants = [],
  trsms,
  setTrsms,
  refreshList,
  fetchTRSMs,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [search, setSearch] = useState("");
  const [trsm, setTrsm] = useState("");
  const [tid, setTid] = useState("");
  const [counter, setCounter] = useState("");
  const [serialNo, setSerialNO] = useState("");
  const [version, setVersion] = useState("");
  const [model, setModel] = useState("");
  const [make, setMake] = useState("");
  const [nsp, setNsp] = useState("");
  const [registerTerminalFeedback, setRegsiterTerminalFeedback] = useState<any>(
    {}
  );

  const merchantOnChange = (merchantId: string) => {
    setSelectedMerchant(merchantId);
  };

  const submit = async () => {
    try {
      setRegsiterTerminalFeedback({});
      setIsLoading(true);
      let response = await createNexoTerminalApi({
        tms2Data: {
          trsm: trsm,
          tid,
          counter,
          serialNo,
          version,
          model,
          make,
          nsp,
        },
        merchant_id: selectedMerchant,
      });
      setRegsiterTerminalFeedback(response.terminals);
      setIsLoading(false);
      if (
        !Object.keys(response.terminals).some(
          (i: any) => response.terminals[i].error
        )
      ) {
        setTimeout(() => {
          refreshList();
          close();
        }, 3000);
      }
    } catch (e: any) {
      setIsLoading(false);
      console.log({ e });
      setRegsiterTerminalFeedback(e);
    }
  };

  const close = () => {
    setRegsiterTerminalFeedback({});
    fetchTRSMs({});
    setSearch("");
    setSelectedMerchant((prevState: string) => "");
    setTrsms({} as ListTRSMResponseApi);
    setIsLoading(false);
    toggleModal();
  };
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      icon={TerminalIcon}
      subTitle={t("terminal")}
      title={t("addNewTerminal")}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={false} />
      <Errors errors={registerTerminalFeedback?.general} />
      <div className="flex flex-col">
        <Select
          data={merchants as any[]}
          value={selectedMerchant}
          label={t("merchant")}
          errors={registerTerminalFeedback?.merchant_id}
          name="merchant-id"
          onChange={(merchantId) => merchantOnChange(merchantId)}
          valueKey="id"
          displayKey="name"
        />
      </div>
      {/** search bar gose here ! */}
      {selectedMerchant && (
        <>
          <div className="py-4">
            <hr />
          </div>

          <form className="">
            <div>
              <Input
                name="trsm"
                placeholder="564646"
                value={trsm}
                onChange={setTrsm}
                label="trsm"
                inputContainerClasses="w-full"
                isDisabled={selectedMerchant ? false : true}
              />
            </div>
            <div>
              <Input
                name="tid"
                placeholder="56464686*****"
                value={tid}
                onChange={setTid}
                label="TID"
                inputContainerClasses="w-full"
                isDisabled={selectedMerchant ? false : true}
              />
            </div>
            <div>
              <Input
                name="counter"
                type="text"
                placeholder="000001"
                value={counter}
                onChange={setCounter}
                label="Counter"
                inputContainerClasses="w-full"
                isDisabled={selectedMerchant ? false : true}
              />
            </div>
            <div>
              <Input
                name="serialNo"
                placeholder="00000014"
                value={serialNo}
                onChange={setSerialNO}
                label="Serial No"
                inputContainerClasses="w-full"
                isDisabled={selectedMerchant ? false : true}
              />
            </div>
            <div>
              <Input
                name="version"
                placeholder="V1.0.0"
                value={version}
                onChange={setVersion}
                label="version"
                inputContainerClasses="w-full"
                isDisabled={selectedMerchant ? false : true}
              />
            </div>
            <div>
              <Input
                name="model"
                placeholder="HALA"
                value={model}
                onChange={setModel}
                label="model"
                inputContainerClasses="w-full"
                isDisabled={selectedMerchant ? false : true}
              />
            </div>
            <div>
              <Input
                name="make"
                placeholder="SOFTPOS NEARPAY"
                value={make}
                onChange={setMake}
                label="make"
                inputContainerClasses="w-full"
                isDisabled={selectedMerchant ? false : true}
              />
            </div>
            <div>
              <Input
                name="nsp"
                placeholder="HALANearpay"
                value={nsp}
                onChange={setNsp}
                label="nsp"
                inputContainerClasses="w-full"
                isDisabled={selectedMerchant ? false : true}
              />
            </div>
          </form>
          {/* <Feedback colorScheme="green" message={registerTerminalFeedback} />
          <Errors errors={registerTerminalFeedback?.terminals || []} /> */}
        </>
      )}
      <div className="my-4">
        <Button
          label={t("save") as string}
          onClick={submit}
          colorScheme="blue"
        />
      </div>
    </Modal>
  );
};

export default ManageNexoTerminals;
