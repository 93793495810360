import { FC } from "react";
import { mergeClassNames } from "../utils";

export interface ErrorsType extends React.HTMLAttributes<HTMLBodyElement> {
  errors: string[];
}

export const Errors: FC<ErrorsType> = ({ errors = [], className }) => {
  return (
    <>
      {errors.map((error, index) => (
        <div
          key={index}
          className={mergeClassNames(
            "rounded-md bg-red-50 p-1 text-xs list-disc gap-y-1 sm:text-sm text-red-700",
            className as string
          )}
        >
          <div className="flex flex-row">{error}</div>
        </div>
      ))}
    </>
  );
};
